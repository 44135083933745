import React from "react";
import { navigate } from "gatsby";

import { SuccessLayoutWrapper } from "../components";

const ResetSuccess = () => {
  return (
    <SuccessLayoutWrapper
      title="Reset complete"
      subTitle="Your password has been set. You can log in again now."
    >
      <button
        className="reset-success__button"
        onClick={() => navigate("/login")}
      >
        Back to Log In
      </button>
    </SuccessLayoutWrapper>
  );
};

export default ResetSuccess;
